import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAnalytics } from '@features/Analytics';
import PageLoading from '../components/common/page-loading/page-loading';
import { useStores } from '../components/common/root-store-provider/root-store-provider';
import page from '../components/pages/page/page';
import { routes } from '../constants/navigation';
const Index = observer(() => {
  const router = useRouter();
  const {
    authStore,
    quizStore: {
      lastScreen,
      setLastScreen
    }
  } = useStores();
  const {
    user,
    access_code,
    setLoginedByWinback
  } = authStore;
  const {
    query,
    isReady
  } = router;
  const {
    trackGoogleEvent
  } = useAnalytics();
  const emailToken = typeof query['email_token'] === 'string' ? query['email_token'] : undefined;
  const accessCode = typeof query['access_code'] === 'string' ? query['access_code'] : undefined;
  const redirect = user !== undefined && isReady && emailToken === undefined && lastScreen || user == undefined;
  useEffect(() => {
    (async () => {
      try {
        if (emailToken) {
          trackGoogleEvent({
            eventName: 'user_from_winback'
          });
          await authStore.loginByEmailToken({
            emailToken: emailToken
          });
        }
      } catch (error) {
        if (error) {
          const newQuery = {
            ...query
          };
          delete newQuery['email_token'];
          delete newQuery['access_code'];
          // noinspection JSIgnoredPromiseFromCall
          await router.replace({
            pathname: routes.quiz,
            query: newQuery
          });
        }
      }
    })();
  }, [authStore, query, emailToken, router, trackGoogleEvent]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: https://github.com/vercel/next.js/issues/18127
  useEffect(() => {
    if (emailToken && (accessCode || access_code)) {
      !access_code && accessCode && authStore.setAccessCode(accessCode);
      setLoginedByWinback(true);
      setLastScreen(true);
      const newQuery = {
        ...query
      };
      delete newQuery['email_token'];
      // noinspection JSIgnoredPromiseFromCall
      router.replace({
        pathname: routes.newPlanPaywall,
        query: newQuery
      });
    }
  }, [emailToken, user]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: https://github.com/vercel/next.js/issues/18127
  useEffect(() => {
    if (redirect) {
      if (!user) {
        // noinspection JSIgnoredPromiseFromCall
        router.replace(routes.quiz);
      } else {
        // noinspection JSIgnoredPromiseFromCall
        router.replace(routes.newPlanPaywall);
      }
    }
  }, [user]);
  return <PageLoading />;
});
export default page(Index);